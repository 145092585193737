/**
 * Created by yangyang on 2017/2/22.
 */
export const documentVo = function () {
  return {
    downloadNo: 0,
    fileSize: 0,
    fileType: '',
    fileUrl: '',
    parentId: -1,
    instanceType: '',
    content: '',
    iconCode: '',
    name: '',
    newName: '',
    creater: '',
    dateCreated: '',
    lastUpdated: '',
    w: null,
    h: null
  }
}
